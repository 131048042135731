import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  devis: [
    {
      id: 4987,
      issuedDate: '13 Dec 2019',
      creadedDate: '11 Nov 2020',
      client: {
        address: '29 rue jean jacque rousseau Mendez Plains',
        company: 'Hall-Robbins PLC',
        companyEmail: 'don85@johnson.com',
        country: 'USA',
        contact: '(616) 865-4180',
        name: 'Jordan Stevenson',
      },
      chantier: {
        address: '15 rue du jeu de mail',
        company: 'FIZZ',
        companyEmail: 'mehdi@1levelup.co',
        country: 'FR',
        contact: '0786399877',
        name: 'Rue jeu de mail',
      },
      service: 'Software Development',
      total: 145428,
      avatar: '',
      invoiceStatus: 'En cours',
      balance: '$724',
      dueDate: '23 Apr 2019',
    },
  ],
}

const liste_ouvrage = {
  ouvrages: [
    {
      id: 1,
      familly: 1,
      category: 2,
      libelle: 'Pose de revetement de sol',
      unite: 'm2',
      description: 'Software Development',
      fourniture: 5,
      heure: 0.3,
      price_id: 1,
      total: '',
      produits: [
        {
          id: 1,
          libelle: 'Parquet Flottant',
          price: 10,
          base_produit: 1,
          marge_time: 0,
          chute: 0,
        },
        {
          id: 1,
          libelle: 'Sous Couche plastique',
          price: 5,
          base_produit: 1,
          marge_time: 0,
          chute: 0,
        },
      ],
    },
    {
      id: 2,
      familly: 2,
      category: 1,
      libelle: 'Dépose de sol',
      unite: 'forfait',
      description: 'on ramasse ton vieux sol',
      fourniture: 0,
      heure: 1,
      price_id: 2,
      total: '',
    },
  ],
}

const catalogue_ouvrage = {
  familles: [
    {
      id: 1,
      name: 'Revetement de sol',
      categorie: [
        {
          id: 1,
          name: 'Carrelage',
        },
        {
          id: 2,
          name: 'Parquet',
        },

      ],
    },
    {
      id: 2,
      name: 'Gros oeuvre',
      categorie: [
        {
          id: 1,
          name: 'Démolition',
        },
        {
          id: 2,
          name: 'Dépose',
        },
        {
          id: 3,
          name: 'Mur',
        },
      ],
    },
  ]
}

const liste_prix = {
  prix: [
    {
      id: 1,
      name: 'Prix grosOeuvre',
      price: 35,
    },
    {
      id: 2,
      name: 'Prix Architec',
      price: 90,
    },
    {
      id: 3,
      name: 'Prix général',
      price: 55,
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet('/pedra/ouvrage/ouvrages').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1, sortBy = 'id', sortDesc = false, status = null } = config.params
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.devis.filter(
    devi =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (devi.client.companyEmail.toLowerCase().includes(queryLowered) ||
      devi.client.name.toLowerCase().includes(queryLowered)) &&
      devi.invoiceStatus === (status || devi.invoiceStatus),
  )
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      devis: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})
