export default [
  {
    path: '/devis',
    name: 'devis',
    component: () => import('@/views/devis/devis-list/devisList.vue'),
  },
  {
    path: '/devis/edit',
    name: 'devis-edit',
    component: () => import('@/views/devis/devis-edit/devisEdit.vue'),
  },
  {
    path: '/devis/show',
    name: 'devis-show',
    component: () => import('@/views/devis/devis-preview/devisPreview.vue'),
  },
  {
    path: '/devis/add',
    name: 'devis-add',
    component: () => import('@/views/devis/devis-add/devisAdd.vue'),
    meta: {
      layout: 'full',
      action: 'read',
    },
  },
  {
    path: '/planning',
    name: 'planning',
    component: () => import('@/views/planning/planning.vue'),
  },
  {
    path: '/planning/chantier',
    name: 'planning-chantier',
    component: () => import('@/views/planning/planning.vue'),
  },
]
