export default [
    {
      path: '/catalogue/catalogue',
      name: 'catalogue',
      component: () => import('@/views/catalogue/ouvrage/catalogue.vue'),
    },
    {
      path: '/catalogue/produit',
      name: 'Liste-produit',
      component: () => import('@/views/catalogue/produit/catalogue.vue'),
    },
    {
      path: '/catalogue/prix',
      name: 'Liste-prix',
      component: () => import('@/views/catalogue/price/prix.vue'),
    },
    {
      path: '/catalogue/ouvrier',
      name: 'Liste-ouvrier',
      component: () => import('@/views/catalogue/user/users-list/UsersList.vue'),
    },
    {
      path: '/catalogue/ouvrier/view/:id',
      name: 'catalogue-users-view',
      component: () => import('@/views/catalogue/user/users-view/UsersView.vue'),
    },
    {
      path: '/catalogue/ouvrier/edit/:id',
      name: 'catalogue-users-edit',
      component: () => import('@/views/catalogue/user/users-edit/UsersEdit.vue'),
    },
  ]
  